import classNames from 'classnames'
import { FormEvent, useMemo, useRef, useState } from 'react'
import TagManager from 'react-gtm-module'
import { AButton } from 'ui/components/atoms/a-button'
import { ACheckbox } from 'ui/components/atoms/a-checkbox'
import { AInput } from 'ui/components/atoms/a-input'
import { ASelect } from 'ui/components/atoms/a-select'
import { ATextarea } from 'ui/components/atoms/a-textarea'
import { getIsTestEmail, useSiteInfo } from 'shared'

type Props = {
  field?: any[]
  showModal?: Function
  className?: string
  csrfToken: string | undefined
}

export const CFormie = ({ field, showModal, className, csrfToken }: Props) => {
  const siteInfo = useSiteInfo()

  const content = useMemo(() => {
    return field && field.length ? field[0] : null
  }, [field])

  const formRef = useRef<HTMLFormElement>(null)

  const [errors, setErrors] = useState<any>({})
  const [status, setStatus] = useState<string>('default')

  const handleAutopilot = () => {
    const formData = new FormData(formRef.current!)
    const interested = Boolean(formData.get('fields[subscription]'))
    const email = formData.get('fields[email]')
    const firstName = formData.get('fields[firstName]')
    const lastName = formData.get('fields[lastName]')
    const zipCode = formData.get('fields[zipCode]')

    const isTestEmail = getIsTestEmail((email as string) ?? '')

    if (!interested || !siteInfo.isUS || isTestEmail) {
      return
    }

    const body = {
      contact: {
        Email: email,
        FirstName: firstName,
        LastName: lastName,
        MailingPostalCode: zipCode,
      },
    }

    fetch(
      process.env.NEXT_PUBLIC_DISCOVERICL_API_PROXY_URL +
        '/autopilot/captureUser.js',
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }
    ).catch((error) => {
      console.error('Autopilot error', error)
    })
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setErrors({})

    const formData = new FormData(formRef.current!)

    const newrelic = (window as any).newrelic
    newrelic?.addPageAction('contactFormSubmitted', {
      requestType: formData.get('requestType'),
      country: formData.get('country'),
    })

    fetch('/actions/formie/submissions/submit', {
      method: 'post',
      cache: 'no-cache',
      headers: { accept: 'application/json' },
      body: formData,
    })
      .then((res) => {
        return res.json()
      })
      .then((res) => {
        if (res?.success) {
          onSuccess()
        } else {
          setErrors(res?.errors || {})
          onError()
        }
      })
      .catch(() => {
        onError()
      })
  }

  const onSuccess = () => {
    setStatus('success')

    handleAutopilot()

    showModal && showModal()

    TagManager.dataLayer({
      dataLayer: {
        event: 'footer_contact_submitted',
      },
    })

    setTimeout(() => {
      setStatus('default')
      formRef.current!.reset()
    }, 3e3)
  }

  const onError = () => {
    setStatus('default')

    const newrelic = (window as any).newrelic
    newrelic?.noticeError('contactFormSubmissionError')
  }

  if (!content?.id) {
    return <></>
  }

  return (
    <section className={`c-formie mt-6 ${className}`}>
      <form
        ref={formRef}
        noValidate
        className={classNames(
          'transition-opacity duration-300 ease-out',
          status === 'default' ? '' : 'opacity-50 pointer-events-none'
        )}
        onSubmit={onSubmit}
      >
        <input type="hidden" name="CRAFT_CSRF_TOKEN" defaultValue={csrfToken} />
        <input type="hidden" name="handle" defaultValue={content.handle} />
        <input type="hidden" name="siteId" defaultValue={content.siteId} />

        {content.pages?.map((page: any, i: number) => (
          <div key={i}>
            {page.rows?.map((row: any, j: number) => (
              <div key={j} className="-mx-3 md:flex">
                {row.fields?.map((item: any, k: number) => (
                  <div key={k} className="block w-full px-3 mt-6">
                    <label
                      htmlFor={`fields[${item.handle}]`}
                      className={classNames(
                        'mb-2 uppercase text-button text-neutral-dark-gray',
                        {
                          hidden: siteInfo.isUS || item.displayName === 'Html',
                        }
                      )}
                    >
                      {item.required && '*'} {item.label}
                    </label>

                    {['Field_Agree'].includes(item.typeName) && (
                      <ACheckbox
                        checked={item.defaultValue}
                        invalid={!!errors[item.handle]}
                        label={item.instructions}
                        name={`fields[${item.handle}]`}
                      />
                    )}

                    {['Field_SingleLineText', 'Field_Email'].includes(
                      item.typeName
                    ) && (
                      <AInput
                        invalid={!!errors[item.handle]}
                        name={`fields[${item.handle}]`}
                        placeholder={
                          siteInfo.isUS ? item.label : item.placeholder
                        }
                        required={item.required}
                        type="text"
                        className={
                          siteInfo.isUS
                            ? 'rounded-full !border-neutral-black placeholder-neutral-black'
                            : undefined
                        }
                      />
                    )}

                    {['Field_Dropdown'].includes(item.typeName) && (
                      <ASelect
                        invalid={!!errors[item.handle]}
                        name={`fields[${item.handle}]`}
                        options={
                          siteInfo.isUS
                            ? [
                                {
                                  value: '',
                                  label: item.label,
                                  disabled: false,
                                },
                                ...item.options,
                              ]
                            : item.options
                        }
                        required={item.required}
                        className={
                          siteInfo.isUS
                            ? '!rounded-full !border-neutral-black !text-neutral-black'
                            : undefined
                        }
                      />
                    )}

                    {['Field_MultiLineText'].includes(item.typeName) && (
                      <ATextarea
                        invalid={!!errors[item.handle]}
                        name={`fields[${item.handle}]`}
                        placeholder={
                          siteInfo.isUS ? item.label : item.placeholder
                        }
                        required={item.required}
                        rows={6}
                        className={
                          siteInfo.isUS
                            ? 'rounded-3xl !border-neutral-black placeholder-neutral-black'
                            : undefined
                        }
                      />
                    )}

                    {['Field_Html'].includes(item.typeName) && (
                      <div
                        className="text-neutral-dark-gray text-button w-full [&>p>a]:underline [&>p>a]:text-primary-dark-teal"
                        dangerouslySetInnerHTML={{ __html: item.htmlContent }}
                      />
                    )}

                    {errors[item.handle]?.map(
                      (error: string, index: number) => (
                        <p key={index} className="text-secondary-red">
                          {error}
                        </p>
                      )
                    )}
                  </div>
                ))}
              </div>
            ))}

            <div className="block w-full px-3 mt-6">
              <AButton color="teal" style="solid" type="submit">
                {page.settings.submitButtonLabel}
              </AButton>
            </div>
          </div>
        ))}
      </form>
    </section>
  )
}

export const CFormieFragments = {
  field: `
    fragment FormieFormFragment on FormInterface {
        id
        title
        handle
        siteId
        csrfToken {
            name
            value
        }
        captchas {
            handle
            name
            value
        }
        pages {
            name
            id
            settings {
                submitButtonLabel
                backButtonLabel
                showBackButton
                buttonsPosition
            }
            rows {
                fields {
                    ...FormieFieldFragment
                    ... on Field_Repeater {
                        fields {
                            ...FormieFieldFragment
                        }
                    }
                    ... on Field_Group {
                        fields {
                            ...FormieFieldFragment
                        }
                    }
                }
            }
        }
    }
    fragment FormieFieldFragment on FieldInterface {
        id
        name
        label: name
        handle
        instructions
        required
        type
        displayName
        typeName
        inputTypeName
        limit
        limitType
        limitAmount
        placeholder
        errorMessage
        labelPosition
        instructionsPosition
        cssClasses
        enableConditions
        conditions
        enableContentEncryption
        visibility
        containerAttributes {
            label
            value
        }
        inputAttributes {
            label
            value
        }
        ... on Field_Agree {
            defaultState
            checkedValue
            uncheckedValue
            descriptionHtml
        }
        ... on Field_Dropdown {
            multiple
            defaultValue
            options {
                label
                value
            }
        }
        ... on Field_Email {
            defaultValue
        }
        ... on Field_MultiLineText {
            defaultValue
        }
        ... on Field_SingleLineText {
            defaultValue
        }
        ... on Field_Html {
            htmlContent
        }
    }
  `,
}
