import React, { useMemo, useRef, useState, useEffect, useContext } from 'react'
import classNames from 'classnames'
import {
  CraftAssetField,
  CraftLinkItField,
  CraftEmbeddedAssetField,
} from 'tsconfig/craft-types'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import SvgEvoVisianIclLogo from 'ui/svg/logos/evo-visian-icl/evo-icl-linear-black.svg'
import SvgArrowDownIcon from 'ui/svg/icons/stroke/arrow-down.svg'
import { AButton, APicture, ASvg } from 'ui'
import useStateRef from 'discovericl/hooks/use-state-ref'
import Hls from 'hls.js'
import { throttle } from 'lodash'
import * as CONST from 'ui/constants/index'
import { MastheadContext } from '../../context/masthead'
import './c-masthead.css'

export type CMastheadBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  backgroundFrames?: Array<CraftAssetField>
  backgroundImage?: Array<CraftAssetField>
  backgroundImageMobile?: Array<CraftAssetField>
  backgroundVideo?: Array<CraftEmbeddedAssetField>
  backgroundVideoPoster?: Array<CraftAssetField>
  backgroundOpacity?: '100' | '10'
  alignItems?: 'center' | 'start' | 'end'
  textAlign?: 'center' | 'left' | 'right'
  textColor?: 'light' | 'dark'
  typographyVariant?: string
  heading?: string
  copy?: string
  additionalName?: string
  additionalOccupation?: string
  button: CraftLinkItField
  buttonVariant?: string
  scrollButton?: string
  scrollButtonStyle?: string
  mobileHeaderAtTop?: boolean
}

type Props = {
  field: Array<CMastheadBlock>
  lensBranding?: string
  isHomepage?: boolean
}

const defaultProps = {
  lensBranding: '',
}

const CMasthead = ({ field, lensBranding, isHomepage }: Props) => {
  const [isActive, setIsActive] = useState(true)
  const [blocksActive, setBlocksActive, blocksActiveRef] = useStateRef([])
  const [blocksOpacity, setBlocksOpacity, blocksOpacityRef] = useStateRef([])
  const [backgroundFrames, setBackgroundFrames] = useState<
    Array<HTMLImageElement>
  >([])
  const [backgroundCanvasContext, setBackgroundCanvasContext] =
    useState<CanvasRenderingContext2D | null>(null)
  const [windowWidth, setWindowWidth] = useState<number>(0)
  const designBreakpoint = 1280

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth)
  }

  const handleWindowResizeThrottled = throttle(handleWindowResize, 100)
  const backgroundAnimation = {
    frame: 0,
  }

  const mastheadContext = useContext(MastheadContext)

  const componentRef = useRef<HTMLDivElement>(null)
  const backgroundCanvasRef = useRef<HTMLCanvasElement>(null)
  const backgroundVideoRef = useRef<Array<HTMLMediaElement | null>>([])
  const blockNormalRef = useRef<Array<HTMLElement | null>>([])

  const { t } = useTranslation('common')
  const router = useRouter()

  const content = useMemo(() => {
    if (field && field.length) {
      let main = field.find((block) => block.typeHandle === 'main')
      if (router.pathname === '/harmony') {
        const branding =
          lensBranding === CONST.BRANDED.VISIAN_ICL
            ? CONST.SITE_LAYOUT.ICL
            : CONST.SITE_LAYOUT.EVO
        main = Object.assign({}, main, { backgroundFrames: [] })
        for (let frameIndex = 0; frameIndex <= 120; frameIndex++) {
          // @ts-ignore
          main.backgroundFrames?.push({
            // Note that this URL is extensionless, can't check for webp support till mounted hook runs
            // Also, this code can run server-side, and async func dont below in a computede func
            url: `${
              process.env.NEXT_PUBLIC_DISCOVERICL_BACKEND_URL
            }frames/harmony/${branding}/${frameIndex
              .toString()
              .padStart(4, '0')}`,
            title: 'Blinking Eye with Lens',
          })
        }
      }
      return {
        ...main,
        blocks: field.filter((block) => block.typeHandle === 'block'),
      }
    } else {
      return null
    }
  }, [field, lensBranding])

  const handleWindowScroll = () => {
    const isIntersecting: boolean =
      (componentRef.current
        ? componentRef.current.getBoundingClientRect().top <=
          window.innerHeight * 1.25
        : false) &&
      (componentRef.current
        ? componentRef.current.getBoundingClientRect().bottom >=
          window.innerHeight * 1.25
        : false)

    mastheadContext?.setIsIntersecting(isIntersecting)

    blockNormalRef.current.forEach((element, index) => {
      setBlocksActive(
        blocksActiveRef.current.map((item: boolean, itemIndex: number) => {
          if (itemIndex === index) {
            return (
              (element
                ? element.getBoundingClientRect().top <=
                  window.innerHeight * 0.5
                : false) &&
              (element
                ? element.getBoundingClientRect().bottom >=
                  window.innerHeight * 0.5
                : false)
            )
          }

          return item
        })
      )
      if (index > 0) {
        let blockOpacity = element
          ? (element.getBoundingClientRect().top / window.innerHeight) * -1 + 1
          : 0
        blockOpacity = blockOpacity * (Math.abs(blockOpacity) + 0.5) // Make images stay solid for longer
        blockOpacity = Math.max(Math.min(blockOpacity, 1), 0)

        setBlocksOpacity(
          blocksOpacityRef.current.map((item: number, itemIndex: number) => {
            if (itemIndex === index) {
              return blockOpacity
            }

            return item
          })
        )
      }
    })
    setIsActive(blocksActive.includes(true))
  }

  const checkWebPSupport = async () => {
    return new Promise((resolve) => {
      const img = new Image()
      img.onload = function () {
        resolve(true)
      }
      img.onerror = function () {
        resolve(false)
      }
      img.src = 'http://www.gstatic.com/webp/gallery/1.webp'
    })
  }

  const createBackgroundFrames = async () => {
    const webpSupport = await checkWebPSupport()

    if (content?.backgroundFrames) {
      setBackgroundFrames(
        Array.from(content.backgroundFrames, (backgroundFrame) => {
          const backgroundFrameImage = new Image()
          backgroundFrameImage.src = `${backgroundFrame.url}.${
            webpSupport ? 'webp' : 'jpg'
          }`
          backgroundFrameImage.alt = backgroundFrame.title ?? ''
          return backgroundFrameImage
        })
      )
    } else {
      setBackgroundFrames([])
    }
  }

  const renderBackgroundContext = () => {
    // draw current frame on canvas
    backgroundCanvasContext?.clearRect(0, 0, 1600, 900)
    backgroundCanvasContext?.drawImage(
      backgroundFrames[backgroundAnimation.frame],
      0,
      0
    )
  }

  const setScrollTrigger = () => {
    // set scroll trigger to animate frames
    gsap
      .timeline({
        scrollTrigger: {
          trigger: componentRef.current,
          start: 'top top',
          end: 'bottom bottom',
          scrub: 1,
        },
      })
      .fromTo(
        backgroundAnimation,
        {
          frame: 0,
        },
        {
          frame: backgroundFrames.length - 1,
          snap: 'frame',
          onUpdate: renderBackgroundContext,
        }
      )
  }

  const handleScrollButtonClick = (blockIndex: number) => {
    if (typeof window !== 'undefined') {
      const element = blockNormalRef.current[blockIndex]
      // 106px  is the sum of the height between the header and the navigation
      window.scrollTo({
        top: element ? element.getBoundingClientRect().bottom + window.pageYOffset - 106 : 0,
        behavior: 'smooth'
      })
    }
  }

  useEffect(() => {
    blockNormalRef.current = blockNormalRef.current.slice(
      0,
      content?.blocks.length
    )
  }, [content?.blocks])

  useEffect(() => {
    setBlocksActive(Array(blockNormalRef.current.length).fill(false))
    setBlocksOpacity(
      Array(blockNormalRef.current.length).fill(1, 0, 1).fill(0, 1)
    )

    window.addEventListener('scroll', handleWindowScroll)
    handleWindowScroll()

    if (content?.backgroundFrames && content?.backgroundFrames?.length) {
      ;(async () => {
        await createBackgroundFrames()
      })()
      setBackgroundCanvasContext(
        backgroundCanvasRef.current?.getContext('2d') ?? null
      )
    }

    content?.blocks.forEach((block, blockIndex) => {
      if (block.backgroundVideo?.length) {
        const assetUrl = block.backgroundVideo[0].embeddedAsset?.url || ''
        const videoUrl = block.backgroundVideo[0].url || ''
        const videoEl = backgroundVideoRef.current[blockIndex]!

        if (assetUrl && Hls.isSupported()) {
          const hls = new Hls()
          hls.on(Hls.Events.ERROR, console.error)
          hls.loadSource(assetUrl)
          hls.attachMedia(videoEl)
        } else if (
          assetUrl &&
          videoEl.canPlayType('application/vnd.apple.mpegurl')
        ) {
          videoEl.src = assetUrl
        } else if (videoUrl) {
          videoEl.src = videoUrl
        }
      }
    })

    return () => {
      window.removeEventListener('scroll', handleWindowScroll)
      mastheadContext?.setIsIntersecting(false)
    }
  }, [content])

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    renderBackgroundContext()
    gsap.fromTo(
      backgroundAnimation,
      {
        frame: 0,
      },
      {
        frame: 0,
        snap: 'frame',
        onUpdate: renderBackgroundContext,
        onComplete: setScrollTrigger,
      }
    )

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill())
    }
  }, [backgroundFrames])
  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResizeThrottled)

    return () => {
      window.removeEventListener('resize', handleWindowResizeThrottled)
    }
  }, [])

  type CustomHeadingPropsType = {
    type: string
    children: JSX.Element
    className: string
  }

  const CustomHeading = ({
    type,
    children,
    className,
  }: CustomHeadingPropsType) => {
    if (type === 'h1') {
      return <h1 className={className}>{children}</h1>
    } else if (type === 'h2') {
      return <h2 className={className}>{children}</h2>
    }

    return <></>
  }

  return (
    <div
      id={content?.idName}
      className={classNames('c-masthead', {
        'is-active': isActive,
      })}
      ref={componentRef}
    >
      {content?.blocks[0]?.mobileHeaderAtTop && !isHomepage && (
        <div className="c-masthead__spacer md:hidden" />
      )}
      <div className="sticky top-0 w-full h-screen c-masthead__blocks c-masthead__blocks--fade">
        {content?.backgroundFrames && content?.backgroundFrames.length && (
          <div className="absolute top-0 left-0 w-full h-full c-masthead__block c-masthead__block--frames bg-neutral-black">
            <canvas
              ref={backgroundCanvasRef}
              className="absolute top-0 left-0 object-cover w-full h-full opacity-75"
              width="1600"
              height="900"
              aria-label="Masthead Video"
            />
          </div>
        )}
        {content?.blocks.map((block, blockIndex) => {
          return (
            <section
              key={block.id}
              className={classNames(
                'absolute top-0 left-0 w-full h-full c-masthead__block c-masthead__block--fade flex flex-col',
                {
                  'bg-neutral-white': !(
                    content.backgroundFrames && content.backgroundFrames.length
                  ),
                }
              )}
              style={{
                opacity: blocksOpacity[blockIndex],
                pointerEvents: blocksActive[blockIndex] ? 'auto' : 'none',
              }}
            >
              {!(
                content.backgroundFrames && content.backgroundFrames.length
              ) && (
                <div
                  className={classNames('', {
                    'flex-shrink overflow-hidden xl:h-1':
                      block.mobileHeaderAtTop,
                    'opacity-10': block.backgroundOpacity === '10',
                  })}
                >
                  {block.heading && block?.mobileHeaderAtTop && !isHomepage && (
                    <CustomHeading
                      type={blockIndex === 0 ? 'h1' : 'h2'}
                      className="c-masthead__heading p-3 md:hidden font-semibold text-neutral-dark-gray text-center"
                    >
                      <span
                        className="whitespace-pre-line"
                        dangerouslySetInnerHTML={{
                          __html: block.heading.replace('\n', '<br>'),
                        }}
                      />
                    </CustomHeading>
                  )}
                  {!!block.backgroundVideo?.length && (
                    <video
                      className={classNames(
                        'object-cover w-full c-masthead__block--media',
                        {
                          'c-masthead__block--scale':
                            block.alignItems === 'start',
                          'absolute top-0 left-0 h-full':
                            !block.mobileHeaderAtTop,
                          'xl:absolute xl:top-0 xl:left-0 xl:h-full':
                            block.mobileHeaderAtTop,
                        }
                      )}
                      autoPlay
                      loop
                      muted
                      playsInline
                      aria-label={
                        block.backgroundVideo?.length
                          ? block.backgroundVideo[0].title
                          : ''
                      }
                      poster={
                        block.backgroundVideoPoster?.length
                          ? block.backgroundVideoPoster[0].url
                          : ''
                      }
                      ref={(el) => {
                        backgroundVideoRef.current[blockIndex] = el
                      }}
                    />
                  )}
                  {!block.backgroundVideo?.length && (
                    <APicture
                      className={classNames(
                        'object-cover w-full c-masthead__block--media',
                        {
                          'c-masthead__block--scale':
                            block.alignItems === 'start',
                          'absolute top-0 left-0 h-full':
                            !block.mobileHeaderAtTop,
                          'xl:absolute xl:top-0 xl:left-0 xl:h-full':
                            block.mobileHeaderAtTop,
                        }
                      )}
                      imageDesktop={
                        block.backgroundImage && block.backgroundImage[0]
                      }
                      imageMobile={
                        block.backgroundImageMobile &&
                        block.backgroundImageMobile[0]
                      }
                    />
                  )}
                </div>
              )}
              <div
                className={classNames(
                  'relative flex flex-col flex-grow justify-center xl:h-full p-6 xl:pt-28 2xl:pt-6',
                  {
                    'items-start':
                      !block.mobileHeaderAtTop && block.alignItems === 'start',
                    'items-end':
                      !block.mobileHeaderAtTop && block.alignItems === 'end',
                    'items-center xl:items-start':
                      block.mobileHeaderAtTop && block.alignItems === 'start',
                    'items-center xl:items-end':
                      block.mobileHeaderAtTop && block.alignItems === 'end',
                    'items-center': block.alignItems === 'center',
                  }
                )}
              >
                <div
                  className={classNames(
                    'md:w-full transition-opacity duration-500 c-masthead__body md:py-5 xl:py-0 px-3',
                    {
                      'text-neutral-dark-gray':
                        block.textColor === 'dark' ||
                        (windowWidth < designBreakpoint &&
                          block.mobileHeaderAtTop),
                      'text-neutral-white': block.textColor !== 'dark',
                      'c-masthead__body--large':
                        block.typographyVariant === 'largeCopy',
                      'c-masthead__body--with-logo':
                        block.typographyVariant === 'withLogo',
                      'opacity-0': !blocksActive[blockIndex],
                      'delay-300': blocksActive[blockIndex],
                      'text-left':
                        block.textAlign === 'left' && !block.mobileHeaderAtTop,
                      'text-right':
                        block.textAlign === 'right' && !block.mobileHeaderAtTop,
                      'text-center xl:text-left':
                        block.textAlign === 'left' && block.mobileHeaderAtTop,
                      'text-center xl:text-right':
                        block.textAlign === 'right' && block.mobileHeaderAtTop,
                      'text-center': block.textAlign === 'center',
                      'md:pl-5 xl:pl-44': block.alignItems === 'start',
                      'md:pr-5 xl:pr-44': block.alignItems === 'end',
                      'xl:mt-auto': block.scrollButtonStyle === 'textIcon',
                    }
                  )}
                >
                  {block.typographyVariant === 'withLogo' && (
                    <div className="w-full flex justify-center">
                      <ASvg
                        className="c-masthead__logo fill-current text-neutral-white mb-4 md:mb-10"
                        svg={SvgEvoVisianIclLogo}
                        ariaLabel={`EVO Visian ${t('logo')}`}
                      />
                    </div>
                  )}
                  {block.heading && (
                    <CustomHeading
                      type={blockIndex === 0 ? 'h1' : 'h2'}
                      className={classNames('c-masthead__heading', {
                        'text-h7 font-semibold':
                          block.typographyVariant === 'largeCopy' &&
                          !block?.mobileHeaderAtTop,
                        'xl:text-h7 xl:font-semibold':
                          block.typographyVariant === 'largeCopy' &&
                          block?.mobileHeaderAtTop,
                        'hidden md:block xl:mt-30 xl:mb-16':
                          block?.mobileHeaderAtTop && !isHomepage,
                        'xl:text-h4':
                          block.typographyVariant !== 'largeCopy' &&
                          block?.mobileHeaderAtTop,
                        'md:text-h4':
                          block.typographyVariant !== 'largeCopy' &&
                          !block?.mobileHeaderAtTop,
                        'font-normal text-h6 md:font-light':
                          block.typographyVariant !== 'largeCopy',
                      })}
                    >
                      <span
                        className="whitespace-pre-line"
                        dangerouslySetInnerHTML={{
                          __html: block.heading.replace('\n', '<br>'),
                        }}
                      />
                    </CustomHeading>
                  )}
                  {block.copy && (
                    <div
                      className={classNames('mt-3 c-masthead__copy s-wysiwyg', {
                        'font-semibold text-p': ['large', 'small'].includes(
                          block.typographyVariant ?? ''
                        ),
                        'md:text-p-lg md:font-normal mt-4':
                          block.typographyVariant === 'large',
                        'text-p-lg mt-4':
                          block.typographyVariant === 'largeCopy',
                        'md:text-h5 font-light':
                          block.typographyVariant === 'largeCopy' &&
                          !block.mobileHeaderAtTop,
                        'xl:text-h5 font-light':
                          block.typographyVariant === 'largeCopy' &&
                          block.mobileHeaderAtTop,
                        'text-p-lg font-light mt-4':
                          block.typographyVariant === 'withLogo',
                      })}
                      dangerouslySetInnerHTML={{
                        __html: block.copy.replace('\n', '<br>'),
                      }}
                    />
                  )}
                  {block.additionalName && (
                    <div className="mt-4 md:mt-8">
                      <h3 className="font-semibold text-xl">
                        {block.additionalName}
                      </h3>
                      {block.additionalOccupation && (
                        <h4 className="text-base">
                          {block.additionalOccupation}
                        </h4>
                      )}
                    </div>
                  )}
                  {block.button.url && (
                    <div
                      className={classNames(
                        'mt-8 transition-transform duration-500 transform',
                        {
                          'mt-8': block.typographyVariant === 'large',
                          'translate-y-8': !blocksActive[blockIndex],
                          'delay-500': blocksActive[blockIndex],
                        }
                      )}
                    >
                      <AButton
                        color={
                          windowWidth < designBreakpoint &&
                          block?.mobileHeaderAtTop
                            ? 'black'
                            : block.buttonVariant === 'solidTeal'
                            ? 'teal'
                            : 'white'
                        }
                        style={
                          windowWidth < designBreakpoint &&
                          block?.mobileHeaderAtTop
                            ? 'outline'
                            : 'solid'
                        }
                        type={block.button.type === 'url' ? 'a' : 'link'}
                        size="lg"
                        target={block.button.target}
                        href={block.button.url}
                      >
                        <span>{block.button.text}</span>
                      </AButton>
                    </div>
                  )}
                </div>
                {block.scrollButton && (
                  <div
                    className={classNames(
                      'w-full justify-center transition-transform duration-500 transform align-self',
                      {
                        'hidden xl:flex': block?.mobileHeaderAtTop,
                        flex: !block?.mobileHeaderAtTop,
                        'translate-y-8': !blocksActive[blockIndex],
                        'delay-500': blocksActive[blockIndex],
                        'mt-8': block.scrollButtonStyle !== 'textIcon',
                        'xl:mt-auto mb-10':
                          block.scrollButtonStyle === 'textIcon',
                      }
                    )}
                  >
                    {(!block.scrollButtonStyle ||
                      block.scrollButtonStyle === 'button') && (
                      <button
                        className="bg-neutral-white rounded-full p-[8px] text-neutral-dark-gray hover:bg-primary-teal hover:text-neutral-white duration-300 ease-out"
                        onClick={() => handleScrollButtonClick(blockIndex)}
                      >
                        <ASvg
                          className="w-6 h-6 stroke-current"
                          svg={SvgArrowDownIcon}
                          ariaLabel={t('arrow-down') ?? ''}
                        />
                      </button>
                    )}
                    {block.scrollButtonStyle === 'textIcon' && (
                      <button
                        className={classNames(
                          'flex flex-col items-center font-bold uppercase c-masthead__scroll-text text-button',
                          {
                            'text-neutral-dark-gray':
                              block.textColor === 'dark',
                            'text-neutral-white': block.textColor !== 'dark',
                          }
                        )}
                        onClick={() => handleScrollButtonClick(blockIndex)}
                      >
                        <span>{t('scroll')}</span>
                        <ASvg
                          className="stroke-current c-masthead__scroll-icon w-8 h-8"
                          svg={SvgArrowDownIcon}
                          ariaLabel={t('arrow-down') ?? ''}
                        />
                      </button>
                    )}
                  </div>
                )}
              </div>
            </section>
          )
        })}
      </div>
      <div className="c-masthead__blocks c-masthead__blocks--normal">
        {content?.blocks.map((block, i) => {
          return (
            <section
              className="h-screen"
              id={block.idName}
              key={block.id}
              ref={(el) => {
                blockNormalRef.current[i] = el
              }}
            />
          )
        })}
      </div>
    </div>
  )
}

CMasthead.defaultProps = defaultProps

export { CMasthead }

const mainFields = `
  id
  typeHandle
  idName
  backgroundFrames {
    url @transform(mode: "crop", width: 1440, height: 900, immediately: true)
    title
  }
`

const blockFields = `
  id
  typeHandle
  idName
  backgroundImage {
    url @transform(mode: "crop", width: 2000, immediately: true)
    title
    hasFocalPoint
    focalPoint
  }
  backgroundImageMobile {
    url @transform(mode: "crop", width: 640, immediately: true)
    title
    hasFocalPoint
    focalPoint
  }
  backgroundVideo {
    url
    title
    embeddedAsset {
      title
      url
      providerName
    }
  }
  backgroundVideoPoster {
    url @transform(mode: "crop", width: 2000, immediately: true)
    title
  }
  backgroundOpacity
  alignItems
  textAlign
  textColor
  typographyVariant
  heading
  copy
  additionalName
  additionalOccupation
  button {
    type
    url
    text
    target
  }
  buttonVariant
  scrollButton
  scrollButtonStyle
  mobileHeaderAtTop
`

export const CMastheadFragments = {
  field: `
    fragment masthead on masthead_MatrixField {
      ... on masthead_main_BlockType {
        ${mainFields}
      }
      ... on masthead_block_BlockType {
        ${blockFields}
      }
    }
  `,
  uiField: `
    fragment uiMasthead on uiMasthead_MatrixField {
      ... on uiMasthead_block_BlockType {
        ${blockFields}
      }
    }
  `,
}
