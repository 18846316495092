import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import SvgArrowDownIcon from 'ui/svg/icons/stroke/arrow-down.svg'
import SvgCloseIcon from 'ui/svg/icons/stroke/close.svg'
import SvgTooltipIcon from 'ui/svg/icons/fill/tooltip.svg'
import { AButton, ASvg, MImageWrapper, MVideoThumbnail } from 'ui'
import './c-hero.css'
import { CraftEmbeddedAssetField, CraftLinkItField } from 'tsconfig/craft-types'
import { useRouter } from 'next/router'

export type CHeroBlock = {
  id?: string
  typeHandle?: string
  beforeHeading?: string
  heading?: string
  headingSecondary?: string
  displayHeadingOnTheSide: boolean
  featuredContent?: string
  displayContinueCta: boolean
  heroContent?: string
  borders?: string
  tooltipText?: string
  image?: Array<{
    url: string
    title?: string
    height: number
    width: number
  }>
  video?: CraftEmbeddedAssetField[]
  cta?: CraftLinkItField
}

type Props = {
  field: Array<CHeroBlock>
  isProcedure?: boolean
}

const CHero = ({ field, isProcedure = false }: Props) => {
  const { t } = useTranslation('common')
  const {
    beforeHeading,
    heading,
    displayHeadingOnTheSide,
    heroContent,
    featuredContent,
    image,
    displayContinueCta,
    video,
    borders,
    cta,
    tooltipText,
  } = field?.[0] ?? []
  const router = useRouter()
  const isHomepage = Boolean(router.asPath === '/')
  const blockNormalRef = useRef<HTMLDivElement | null>(null)
  const isLargeBorder = Boolean(borders === 'large')
  const isMp4 = Boolean(video?.[0] && !video?.[0]?.embeddedAsset)
  const [modalOpen, setModalOpen] = useState(false)

  const handleScrollButtonClick = () => {
    if (typeof window !== 'undefined') {
      const element = blockNormalRef.current
      // 106px  is the sum of the height between the header and the navigation
      window.scrollTo({
        top: element
          ? element.getBoundingClientRect().bottom + window.pageYOffset - 106
          : 0,
        behavior: 'smooth',
      })
    }
  }

  const handleTooltipClick = (param: boolean) => {
    setModalOpen(param)
  }

  const heroFooter = field.find((item) => item.typeHandle === 'blockFooter')

  return (
    <>
      <div
        ref={blockNormalRef}
        className={classNames('c-hero__wrapper', {
          'c-hero__bg-lg': isLargeBorder,
          'c-hero__bg-md': !isLargeBorder,
          'small-hero': isProcedure,
        })}
      >
        <div className="o-container o-container--xl">
          {beforeHeading && (
            <h2 className="uppercase tracking-wide md:text-center mb-[20px] md:mb-[40px]">
              {beforeHeading}
            </h2>
          )}
          {heading && (
            <h1
              className={classNames(
                'text-4xl leading-10 lg:text-heading lg:mb-[90px] text-center font-semibold drop-shadow-[0px_4px_4px_rgba(0,0,0,0.05)]',
                {
                  'md:block': !displayHeadingOnTheSide,
                  'md:hidden': displayHeadingOnTheSide,
                }
              )}
            >
              {heading}
            </h1>
          )}
          {isHomepage && tooltipText && (
            <div className="text-center mx-auto text-lg md:hidden mt-[25px] ">
              {tooltipText}
              <span className="toolTip inline-block ml-2" onClick={() => handleTooltipClick(true)}>
                <ASvg
                  className="stroke-current flex justify-center"
                  svg={SvgTooltipIcon}
                  ariaLabel={t('tooltip') ?? ''}
                />
              </span>
            </div>
          )}
          {cta?.url && (
            <div className={'flex justify-center md:hidden'}>
              <AButton
                className="w-full mt-[10px] mb-[15px] text-primary-dark-teal text-lg"
                type={cta?.type === 'custom' || cta?.type === 
                  'entry' || cta?.type === 
                  'url' ? 'a' : cta?.type}
                href={cta?.url}
                rel={cta.type === 'url' ? 'noreferrer' : ''}
                target={cta.target}
                color="white"
                style="solid"
              >
                <span>{cta.text}</span>
              </AButton>
            </div>
          )}
          <div
            className={classNames('c-hero__columns', {
              'mt-[30px] md:mt-[90px]': heading && !displayHeadingOnTheSide,
            })}
          >
            <div
              className={classNames({
                'md:w-[45%]': displayHeadingOnTheSide && heading,
                'md:w-[40%]': heading && !displayHeadingOnTheSide,
              })}
            >
              {heading && (
                <h1
                  className={classNames(
                    'hidden text-3xl md:text-4xl lg:text-6xl font-semibold mb-[25px] drop-shadow-[0px_4px_4px_rgba(0,0,0,0.05)]',
                    {
                      'md:block': displayHeadingOnTheSide,
                    }
                  )}
                >
                  {heading}
                </h1>
              )}
              {heroContent && (
                <div
                  className={classNames(
                    'leading-6 md:leading-8 [&>p>a]:underline [&>p>a]:font-semibold',
                    {
                      'text-lg': displayHeadingOnTheSide,
                      'text-lg md:text-xl lg:text-2xl': !displayHeadingOnTheSide,
                    }
                  )}
                  dangerouslySetInnerHTML={{ __html: heroContent }}
                />
              )}
              {featuredContent && (
                <div
                  className="c-hero__featured-content my-[20px]"
                  dangerouslySetInnerHTML={{ __html: featuredContent }}
                />
              )}
            </div>
            {Boolean(video?.length || image?.length) && (
              <MImageWrapper
                className={classNames('c-hero__image-wrapper', {
                  'flex-1': video?.[0]?.url,
                  'mx-auto': !(video?.[0]?.url),
                })}
              >
                {isMp4 ? (
                   <video
                   className="w-full"
                   width="657"
                   height="452"
                   autoPlay
                   muted
                   playsInline
                   loop
                   aria-label={video?.[0].title ?? ''}
                 >
                   <source
                     src={video?.[0]?.url}
                     type="video/mp4"
                   />
                 </video>
                ) : (
                  <>
                    {video?.[0]?.url ? (
                      <MVideoThumbnail video={video} />
                    ) : (
                      <Image
                        src={image?.[0].url ?? ''}
                        alt={image?.[0].title ?? ''}
                        width={image?.[0].width ?? 0}
                        height={image?.[0].height ?? 0}
                      />
                    )}
                  </> 
                )}
              </MImageWrapper>
            )}
          </div>
          {isHomepage && tooltipText && (
            <div className="text-center mx-auto text-2xl mb-4 only-desktop">
              {tooltipText}
              <span className="toolTip inline-block ml-2" onClick={() => handleTooltipClick(true)}>
                <ASvg
                  className="stroke-current flex justify-center"
                  svg={SvgTooltipIcon}
                  ariaLabel={t('tooltip') ?? ''}
                />
              </span>
            </div>
          )}
          {cta?.url && (
            <div className={'justify-center hidden md:flex'}>
              <AButton
                className="w-full md:w-[420px] text-primary-dark-teal text-lg"
                type={cta?.type === 'custom' || cta?.type === 
                  'entry' || cta?.type === 
                  'url' ? 'a' : cta?.type}
                href={cta?.url}
                rel={cta.type === 'url' ? 'noreferrer' : ''}
                target={cta.target}
                color="white"
                size="lg"
                style="solid"
              >
                <span>{cta.text}</span>
              </AButton>
            </div>
          )}
        </div>
        {displayContinueCta && (
          <div className="c-hero__scroll-btn-wrapper">
            <button
              onClick={handleScrollButtonClick}
              className="c-hero__scroll-btn"
            >
              <ASvg
                className="stroke-current flex justify-center"
                svg={SvgArrowDownIcon}
                ariaLabel={t('arrow-down') ?? ''}
              />
            </button>
          </div>
        )}
      </div>
      {Boolean(heroFooter?.heading) && (
        <div className="c-hero-footer">
          <div className="o-container o-container--xl flex justify-around flex-col md:flex-row px-[20px]">
            {heroFooter?.heading && (
              <div
                className="c-hero-footer__heading"
                dangerouslySetInnerHTML={{ __html: heroFooter?.heading }}
              />
            )}
            {heroFooter?.headingSecondary && (
              <div
                className="c-hero-footer__heading"
                dangerouslySetInnerHTML={{
                  __html: heroFooter?.headingSecondary,
                }}
              />
            )}
          </div>
        </div>
      )}
      {isHomepage && tooltipText && (
        <div className={classNames('absolute modal bg-neutral-white text-black', {
          'hidden': !modalOpen,
          })}>
        <div
          className="modal-content relative"
        >
          <button className="close w-[88px] h-[88px] -right-[44px] -bottom-[44px] bg-gray-900 rounded-full flex items-center justify-center absolute z-50" title="Close" onClick={() => handleTooltipClick(false)}>
            <span className="a-svg stroke-current w-8 h-8 text-primary-teal">
              <ASvg
                className="stroke-current flex justify-center"
                svg={SvgCloseIcon}
                ariaLabel={t('close') ?? ''}
              />
            </span>
          </button>
          <p className="text-lg mb-4">
            Got questions about EVO? Our EVO Advisors are here to help with the first steps in determining if you are a potential candidate, discuss EVO, and assist in scheduling a consultation with a doctor. We're here to guide you every step of the way.
            </p>
            <span className="italic">The EVO Advisor program is for information purposes only and is not a substitute for professional medical advice or consultation with a healthcare professional.</span>
        </div>
        </div>
      )}
    </>
  )
}

export { CHero }

export const CHeroFragment = `
    fragment hero on hero_MatrixField {
      ... on hero_block_BlockType {
        id
        typeHandle
        beforeHeading
        heading
        displayHeadingOnTheSide
        heroContent
        featuredContent
        tooltipText
        image {
          url @transform(mode: "crop", width: 800, immediately: true)
          title
          width
          height
          path
        }
        video {
          url
          title
          embeddedAsset {
            title
            url
            providerName
          }
        }
        borders
        displayContinueCta
        cta {
          type
          url
          text
          target
        }
      }
      ... on hero_blockFooter_BlockType {
        typeHandle
        heading
        headingSecondary
      }
    }
  `